import React from "react"
import { FormattedMessage, IntlProvider } from "react-intl"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Paths from "../../views/pages/landing/Paths"

import messages_en from "../../assets/data/locales/en.json"
import messages_de from "../../assets/data/locales/de.json"
import messages_fr from "../../assets/data/locales/fr.json"
import messages_pt from "../../assets/data/locales/pt.json"
import messages_es from "../../assets/data/locales/es.json"

const menu_messages = {
  en: messages_en,
  de: messages_de,
  fr: messages_fr,
  pt: messages_pt,
  es: messages_es
}

const Context = React.createContext()

class IntlProviderWrapper extends React.Component {
  state = {
    locale: "es",
    messages: menu_messages["es"],
    modal: true,
  }

  setCookie = () => {
    localStorage.setItem('locale', navigator.language.substring(0, 2)); 
    this.toggleModal();
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  setLocale(newLocale){
    this.setState({locale: newLocale, messages: menu_messages[newLocale]});
  }

  componentWillMount(){
    try{
      const savedLocale = localStorage.getItem('locale');
      if(savedLocale !== undefined || savedLocale !== null){
        const newLocale = savedLocale.substring(0, 2);
        if(newLocale === 'es' || newLocale === 'en'){
          this.setState({modal: false, locale: newLocale, messages: menu_messages[newLocale]});
        } else {
          this.setState({modal: false, locale: navigator.language.substring(0, 2), messages: menu_messages[navigator.language.substring(0, 2)]});
        }
      } else {
        this.setState({locale: navigator.language.substring(0, 2), messages: menu_messages[navigator.language.substring(0, 2)]});
      }
    } catch (err){
      console.log(err);
    }
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            this.setState({
              locale: language,
              messages: menu_messages[language]
            })
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale={"us"}
        >
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggleModal}
            className="modal-dialog-centered"
          >
            <ModalHeader toggle={this.toggleModal}>
              <FormattedMessage id="useCookiesHeader"/> 
            </ModalHeader>
            <ModalBody className="modal-dialog-centered">
              <FormattedMessage id="useCookiesBody"/>
            </ModalBody>
            <ModalFooter>
              <Button outline color="primary" onClick={this.toggleModal}
              href={Paths.cookies}>
                <FormattedMessage id="moreInfo"/>
              </Button>
              <Button color="primary" 
               onClick={this.setCookie}
              
              >
                <FormattedMessage id="accept"/>
              </Button>{" "}
            </ModalFooter>
          </Modal>
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
