// const CONFIG = require('./../../../config-front')

//Superadmin
// const adminCenterAdd = "/superadmin/center/add"
// const adminOwnerAdd = "/superadmin/owner/add"
// const adminRegister = "/superadmin/register"
// const adminCenterEdit = "/superadmin/center/add"
// const adminCenterSync = "/superadmin/center/sync"

//Password
// const forgotPassword = "/forgotPassword/"
// const forgotPasswordActivation = "/forgotPassword/activation"

//Customer
const login = "/login";
const dashboard = "/customer/home";
// const logout = "/logout"

// const customerRegisterCheckout = "/customer/register/checkout"
// const customerRegisterActivation = "/customer/register/checkout"
const customerRegister = "/customer/register/checkout";
const center = "/customer/home";

const checkoutPath = "/customer/register/checkout";
const checkoutPathUserIn = "/customer/checkout";

// const customerSupportTickets = "/support/tickets"
// const customerSupportNewTicket = "/support/newTicket"

const customerBills = "/customer/invoices";
// const customerInvoices = "/customer/invoices"
// // const customerInvoices = (id) => {return ("/customer/invoices/" + id)}
// const customerPayments = "/customer/paymentMethods"
const faqPath = "/customer/faq";
const customerEditProfile = "/customer/editProfile";
// const customerEditPassword = "/customer/editPassword"
const customerStorages = "/customer/storage";

//Landing
const landing = "/";
const studentsPath = "/students";
const familiesPath = "/families";
const businessPath = "/business";

const faqPathLanding = "/faq";
const register = "/register";

const advantages = "/advantages";
const offers = "/offers";

const ourCenter = "/center";
const legal = "/legal";
const privacy = "/privacy";
const cookies = "/cookies";
const covid = "/covid";
const contactUs = "/contact-us";

// const legal = "/legal"
const facebook = "https://facebook.com";
const instagram = "https://instagram.com";
//sft-espacios

export const paths = {
  checkoutPath: checkoutPath,
  checkoutPathUserIn: checkoutPathUserIn,
  businessPath: businessPath,
  studentsPath: studentsPath,
  familiesPath: familiesPath,
  dashboard: dashboard,
  faqPathLanding: faqPathLanding,
  faqPath: faqPath,
  legal: legal,
  privacy: privacy,
  cookies: cookies,
  covid: covid,
  advantages: advantages,
  offers: offers,

  contactUs: contactUs,
  facebook: facebook,
  instagram: instagram,

  landing: landing,
  register: register,
  registerCustomer: customerRegister,
  customerStorages: customerStorages,
  customerBills: customerBills,
  customerEditProfile: customerEditProfile,
  login: login,
  center: center,
  ourCenter: ourCenter,
};

export default paths;
