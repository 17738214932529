import mock from "../mock"

export const searchResult = [
  {
    id: 1,
    target: "AnalyticsDashboard",
    title: "Analytics Dashboard",
    link: "/",
    icon: "Home",
    starred: false
  },
  {
    id: 2,
    target: "eCommerceDashboard",
    title: "eCommerce Dashboard",
    link: "/ecommerce-dashboard",
    icon: "Home",
    starred: false
  },
  {
    id: 3,
    target: "Todo",
    title: "Status",
    link: "/status",
    icon: "CheckSquare",
    starred: true
  },
  {
    id: 4,
    target: "Chat",
    title: "Soporte",
    link: "/chat",
    icon: "MessageSquare",
    starred: true
  }
]

mock.onGet("/api/search/bookmarks/data").reply(200, {
  searchResult
})

mock.onPost("/api/update/bookmarks").reply(request => {
  const bookmarkToUpdate = JSON.parse(request.data).obj

  searchResult.filter(i => {
    if (i.id === bookmarkToUpdate.id) {
      return (i.starred = !bookmarkToUpdate.starred)
    } else {
      return null
    }
  })
  return [200]
})
